import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
// import Select from '../../components/Select'
import { Icon, Spinner } from '@blueprintjs/core'
import {
  updatePortfolioManager,
  deletePortfolioManager,
} from '../../redux/modules/PortfolioManagers/actions'
import { fetchFunds } from '../../redux/modules/Funds/actions'
import { fetchTeams } from '../../redux/modules/Teams/actions'
import { updateAnalyst, deleteAnalyst } from '../../redux/modules/Analysts/actions'
import { updateAdministrator, deleteAdministrator } from '../../redux/modules/Administrators/actions'
import { fetchTvAppearances, refreshVideoList } from '../../redux/modules/TVAppearances/actions'
import { yearOptions } from '../TableTools'
import S3Uploader from './S3Uploader'
import PmVideo from '../PortfolioManagers/PmVideo'
import SelectWithAutofill from '../../components/SelectWithAutofill'

class PortfolioManagerEdit extends Component {
  constructor(){
    super()
    this.state = {
      name: "",
      last_name: "",
      title: "",
      bio: "",
      img: "",
      funds: [],
      fundsToAdd: [],
      teams: [],
      teamsToAdd: [],
      videos: [],
      videosToAdd: [],
      url: ""
    }
    this.modalRef = React.createRef()
  }

  componentDidMount(){
    this.props.fetchFunds()
    this.props.fetchTvAppearances()
    if(!this.props.teamsList.length) { this.props.fetchTeams() }
  }

  componentDidUpdate(prevProps){
    const { name, last_name, title, bio, funds, img, start_year, tv_appearances } = this.props.portfolioManager
    if(name && prevProps !== this.props){
      const parsedBio = bio ? this.props.portfolioManager.bio.replace(/<p>/g, "").replace(/<\/p>/g, "\n\n") : ""
      this.setState({
        name,
        last_name,
        title,
        bio: parsedBio,
        img,
        start_year,
        funds: funds || [],
        fundsToAdd: [],
        videos: tv_appearances || []
       })
    }
    if(!prevProps.showModal && this.props.showModal){ this.setState({ videosToAdd: [] }) }
  }

  handleChange = (e) => {
    const { name, value } = e.target
    const { state } = this
    state[name] = value
    this.setState(state)
  }

  handleFundSelect = (fundOption) => {
    if(fundOption){
      const fundToAdd = {
        fund_code: fundOption.value,
        fund_name: fundOption.label,
      }
      this.setState({
        fundsToAdd: [...this.state.fundsToAdd, fundToAdd]
      })
    }
  }

  handleVideoSelect = vidOption => {
    if(vidOption){
      const vidToAdd = {
        title: vidOption.label,
        id: vidOption.value
      }
      this.setState({
        videosToAdd: [...this.state.videosToAdd, vidToAdd] // CHANGE BACK TO vidToAdd ONCE DEALING WITH ACTUAL OBJECTS
      })
    }
  }

  // handleTeamSelect = (teamOption) => {
  //   const teamToAdd = {
  //     id: teamOption.value,
  //     name: teamOption.label,
  //   }
  //   this.setState({
  //     teamsToAdd: [...this.state.teamsToAdd, teamToAdd]
  //   })
  // }

  removePersistedFund = (fundCode) => {
    const { funds } = this.state
    const flagship = funds.find( fund => fund.fund_code === fundCode)
    const siblings = funds.filter( fund => Math.floor(parseInt(fund.websort)) === parseInt(flagship.websort) )
    for(let i = 0; i < siblings.length; i++){
      const fundIndex = funds.indexOf(siblings[i])
      if(fundIndex > -1){ funds.splice(fundIndex, 1) }
    }
    this.setState({ funds })
  }

  removeAddedFund = (fundCode) => {
    const { fundsToAdd } = this.state
    const fundToRemove = fundsToAdd.find( fund => fund.fund_code === fundCode)
    const fundIndex = fundsToAdd.indexOf(fundToRemove)
    if(fundIndex > -1){ fundsToAdd.splice(fundIndex, 1) }
    this.setState({ fundsToAdd })
  }

  removePersistedTeam = (teamId) => {
    const { teams } = this.state
    const teamToRemove = teams.find( team => team.id === teamId )
    const teamIndex = teams.indexOf(teamToRemove)
    if(teamIndex > -1){ teams.splice(teamIndex, 1) }
    this.setState({ teams })
  }

  removeAddedTeam = (teamId) => {
    const { teamsToAdd } = this.state
    const teamToRemove = teamsToAdd.find( team => team.id === teamId )
    const teamIndex = teamsToAdd.indexOf(teamToRemove)
    if(teamIndex > -1){ teamsToAdd.splice(teamIndex, 1) }
    this.setState({ teamsToAdd })
  }

  removePersistedVideo = videoId => {
    const { videos } = this.state
    const videoToRemove = videos.find( video => video.id === videoId )
    const vidIndex = videos.indexOf(videoToRemove)
    if(vidIndex > -1){ videos.splice(vidIndex, 1) }
    this.setState({ videos })
  }

  removeAddedVideo = videoUrl => {
    const { videosToAdd } = this.state
    const vidToRemove = videosToAdd.find( vid => vid["video_url"] === videoUrl )
    const vidIndex = videosToAdd.indexOf(vidToRemove)
    if(vidIndex > -1){ videosToAdd.splice(vidIndex, 1) }
    this.setState({ videosToAdd })
  }

  handleSaveChanges = () => {
    const { name, title, bio, img, start_year } = this.state
    const convertedToHTML = `<p>${bio.replace(/\n\n/g, "</p><p>")}</p>`//replaces linebreaks with p tags
    const updatedPm = {
      id: this.props.portfolioManager.id,
      name,
      title,
      img,
      start_year,
      bio: convertedToHTML,
      funds: [...this.state.funds, ...this.state.fundsToAdd],
      videos: [...this.state.videos, ...this.state.videosToAdd]
    }
    this.updateProperType(updatedPm)
    this.props.handleHide()
  }

  updateProperType = (updatedPm) => {
    const type = this.props.location.pathname.split("/")[2]
    switch(type){
      case "portfolio-manager-admin":
        this.props.updatePortfolioManager(updatedPm)
        break;
      case "administrators":
        this.props.updateAdministrator(updatedPm)
        break;
      case "analysts":
        this.props.updateAnalyst(updatedPm)
        break;
      default:
        return null
    }
  }

  handlePmDelete = () => {
    const { portfolioManager } = this.props
    const isConfirmed = window.confirm(`Delete ${portfolioManager.name}?`)
    if(isConfirmed){
      this.deleteProperType(portfolioManager)
      this.props.toggleModal()
    }
  }

  deleteProperType = (person) => {
    const type = this.props.location.pathname.split("/")[2]
    switch(type){
      case "portfolio-manager-admin":
        this.props.deletePortfolioManager(person.id)
        this.props.history.push("/admin/portfolio-manager-admin")
        break;
      case "administrators":
        this.props.deleteAdministrator(person.id)
        this.props.history.push("/admin/administrators")
        break;
      case "analysts":
        this.props.deleteAnalyst(person.id)
        this.props.history.push("/admin/analysts")
        break;
      default:
        return null
    }
  }

  handleResponse = response => {
    this.setState({ img: response.publicUrl })
  }

  handleVideoAdd = e => {
    e.preventDefault()
    const { videosToAdd, url } = this.state
    const video = {}
    const youtubeId = url.match(/\W\w{11}(\b|\W)/g)
    if(youtubeId && youtubeId.length === 1) {
      video["video_url"] = `https://www.youtube.com/embed${youtubeId[0].replace("=","/")}`
      videosToAdd.push(video)
      this.setState({ videosToAdd, url: "" })
    } else {
      alert("Cannot read youtube URL")
    }
  }

  render(){
    const {
      showModal,
      handleHide,
      portfolioManager,
      // isAnalyst,
      fundsList,
      // teamsList,
      // tvAppearances,
      errors,
      location
    } = this.props
    const fundOptions = (
      fundsList.filter( fund => !this.state.fundsToAdd.some( managedFund => fund.fund_code === managedFund.fund_code ))
      .filter( fund => !this.state.funds.some( managedFund => fund.fund_code === managedFund.fund_code))
      .map( fund => ({ value: fund.fund_code, label: fund.fundlegalname }) )
    )

    // const teamOptions = (
    //   teamsList.filter( team => !this.state.teamsToAdd.some(addedTeam => addedTeam.id === team.id) )
    //   .filter( team => !this.state.teams.some(addedTeam => addedTeam.id === team.id) )
    //   .map( team => ({ value: team.id, label: team.name }))
    // )

    // const videoOptions = (
    //   tvAppearances.filter( video => !video["portfolio_manager"] )
    //   .filter( video => !this.state.videosToAdd.some(addedVid => addedVid.id === video.id) )
    //   .filter( video => !this.state.videos.some(addedVid => addedVid.id === video.id) )
    //   .sort( (vidA, vidB) => vidA.title > vidB.title ? 1 : -1 )
    //   .map( video => ({ value: video.id, label: video.title }))
    // )

    const persistedFunds = this.state.funds.filter( fund => fund.flagship ).map( fund => {
      return (
        <li className="pm-edit-funds-li" key={fund.fund_code}>
          <Icon className="remove-fund-x" icon="cross" onClick={ () => this.removePersistedFund(fund.fund_code)}/>
          {fund.fundlegalname}
        </li>
      )
    })
    const addedFunds = this.state.fundsToAdd.map( fund => {
      return (
        <li className="pm-edit-funds-li" key={fund.fund_code}>
          <Icon className="remove-fund-x" icon="cross" onClick={ () => this.removeAddedFund(fund.fund_code)}/>
          {fund.fund_name}
        </li>
      )
    })
    // const persistedVideos = this.state.videos.map( video => {
    //   return (
    //     <li className="pm-edit-funds-li" key={video.id}>
    //       <Icon className="remove-fund-x" icon="cross" onClick={() => this.removePersistedVideo(video.id)}/>
    //       {video.title}
    //     </li>
    //   )
    // })
    const videos = this.state.videos.map( v => {
      return (
        <PmVideo
          key={v["video_url"]}
          link={v["video_url"]}
          removeVideo={() => this.removePersistedVideo(v.id)}
          isEditing={true}
        />
      )
    })
    const addedVideos = this.state.videosToAdd.map( v => {
      return (
        <PmVideo
          key={v["video_url"]}
          link={v["video_url"]}
          removeVideo={() => this.removeAddedVideo(v["video_url"])}
          isEditing={true}
        />
      )
    })
	console.log( this.state.fundsToAdd );
    return (
      <Modal
        dialogClassName="pm-edit-modal"
        show={showModal}
        onHide={handleHide}
      >
        <Modal.Header closeButton ref={this.modalRef}>
          <Modal.Title id="example-modal-sizes-title-lg">
            {portfolioManager.name} <small>{portfolioManager.title}</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        { false ? <Spinner  size={100} className="funds-spinner"/> :
          <div>
            { !!errors ? <span id="update-error-span">Error: {errors[0]}</span> : null }
            <form >
              <label className="upload-input-label">Name:</label>
              <input className="media-info-input admin-input" type="text" name="name" value={this.state.name}onChange={this.handleChange}></input>
              <br />
              <label className="upload-input-label">Last Name:</label>
              <input className="media-info-input admin-input" type="text" name="last_name" value={this.state.last_name}onChange={this.handleChange}></input>
              <br />
              <label className="upload-input-label">Title:</label>
              <input className="media-info-input admin-input" type="text" name="title" value={this.state.title}onChange={this.handleChange}></input>
              <br />
              <label className="upload-input-label">Start Year:</label>
              <select className="media-info-input admin-select" type="text" name="start_year" value={this.state.start_year }onChange={this.handleChange}>
                <option value=""></option>
                {yearOptions( 1960 )}
              </select>
              <br />
              <label className="upload-input-label">Edit bio:</label>
              <br />
              <textarea name="bio" className="media-info-input" disabled={this.state.textareaDisabled} ref={this.textareaRef} id="pm-bio-editor" rows="10" cols="100" value={this.state.bio} onChange={this.handleChange}></textarea>
              <br />
              <label className="upload-input-label s3-upload-label">Update Headshot:</label>
              <S3Uploader
                handleResponse={this.handleResponse}
                accept='image/*'
                apiPath="portfolio_managers/s3/sign"
              />
            </form>
            {
              location.pathname.includes("administrators") || location.pathname.includes("analysts") ?
              null :
              <div>
                <label>Add/Remove Funds:</label>
                <SelectWithAutofill
                  key="funds"
                  options={fundOptions}
                  className="select-tag media-info-input admin-select"
                  placeholder="Enter a fund..."
                  handleChange={this.handleFundSelect}
                  onClear={(e) => this.handleFundSelect(null)}
                  value={this.state.fund_code}
                />
                <ul>
                  {persistedFunds}
                  {addedFunds}
                </ul>
              </div>
            }
            <div  data-aos-duration="900" data-aos-once="true">
              <h3 id="media-header">Add Videos <small>(Youtube only)</small></h3>
              <form onSubmit={this.handleVideoAdd}>
                <input type="text" name="url" className="media-info-input pm-vid-input admin-input" value={this.state.url} onChange={this.handleChange} placeholder="https://youtube.com/..."></input>
                <input type="submit" className="pm-vid-add media-info-input admin-input" value="Add"></input>
              </form>
              <div id="pm-gtv-container" style={{ width: '100%' }}>
                {addedVideos}
                {videos}
              </div>
            </div>
          </div>
        }
        </Modal.Body>
        <Modal.Footer>
          <button className="pm-edit-btn" id="pm-edit-close" onClick={handleHide}>Close</button>
          <button className="pm-edit-btn" id="pm-edit-save" onClick={this.handleSaveChanges}>Save Changes</button>
          <div id="pm-delete-container">
            <span id="pm-delete-link" onClick={this.handlePmDelete}>Delete</span>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
  }

const mapStateToProps = (state) => ({
  currentlyUpdating: state.portfolioManagers.updatingPm || false,
  fundsList: state.funds,
  teamsList: state.teams,
  errors: state.portfolioManagers.errors,
  tvAppearances: state.tvAppearances.tvAppearances || []
})

export default withRouter(connect(mapStateToProps, {
  fetchFunds,
  fetchTeams,
  fetchTvAppearances,
  refreshVideoList,
  updatePortfolioManager,
  deletePortfolioManager,
  updateAnalyst,
  deleteAnalyst,
  updateAdministrator,
  deleteAdministrator
})(PortfolioManagerEdit))


// <div>
//   <label>
//     Add/Remove Videos:
//     <br/>
//     {
//       videoOptions.length ?
//       <small>
//         (click <span style={{ color: '#005b97', cursor: 'pointer' }} onClick={this.props.refreshVideoList}>here</span> to refresh list of videos)
//       </small>
//       :
//       <span><Spinner size={10} className="mini-spinner"/></span>
//     }
//   </label>
//   <Select
//     key="videos"
//     options={!isAnalyst && videoOptions}
//     className="select-tag media-info-input"
//     placeholder="Select a Video"
//     handleChange={this.handleVideoSelect}
//     onClear={(e) => this.handleVideoSelect(null)}
//     selectKey="2"
//     value={this.state.fund_code}
//   />
//   <ul>
//     {persistedVideos}
//     {addedVideos}
//   </ul>
// </div>

// removePersistedVideo = videoId => {
//   const { videos } = this.state
//   const videoToRemove = videos.find( video => video.id === videoId )
//   const vidIndex = videos.indexOf(videoToRemove)
//   if(vidIndex > -1){ videos.splice(vidIndex, 1) }
//   this.setState({ videos })
// }

// removeAddedVideo = videoId => {
//   const { videosToAdd } = this.state
//   const vidToRemove = videosToAdd.find( vid => vid.id === videoId )
//   const vidIndex = videosToAdd.indexOf(vidToRemove)
//   if(vidIndex > -1){ videosToAdd.splice(vidIndex, 1) }
//   this.setState({ videosToAdd })
// }
